@import '../node_modules/modern-normalize/modern-normalize.css';

$dark: #222831;
$font: #393e46;
$colorful: rgb(240, 61, 61);
$white: #f2f2f2;
$offwhite: #f8f6f6;

:root {
    line-height: 1.5;
    scroll-behavior: smooth;
}

/**
 * Disable smooth scrolling when users have prefers-reduced-motion enabled
 */
 @media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

h1, h2, h3, h4, h5, figure, p, ol, ul {
    margin: 0;
}

ol, ul {
    list-style: none;
    padding-inline: 0;
}

h1, h2, h3, h4, h5 {
    font-size: inherit;
    font-weight: 300;
    font-family: "Jost", sans-serif;
    letter-spacing: 1.5px;
}

img {
    display: block;
    max-inline-size: 100%;
}

p {
    margin-bottom: 1.5em;
}

a {
    color: $colorful;
}

body {
    color: $font;
    background-color: $dark;
    font-family: "Source Code Pro", sans-serif;
    letter-spacing: 0.4px;
    font-weight: 300;
}

hr {
    border: none;
    border-top: 1px solid lighten($font, 60%);
    margin: 2em 0;
}

#header {
    background: $dark;
    height: 100vh;
    background-image: url(../static/header.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#header.varstvo {
    background-image: none;
    height: auto;
}

#header h1 {
    width: 100px;
    height: 100px;
}

#header h1 a {
    display: block;
    background-image: url(../static/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 100%;
    text-indent: -9999px;
}

#header h2 {
    color: $offwhite;
    opacity: 0.7;
    line-height: 1.2em;
    text-transform: none;
    letter-spacing: 1px;
}

@media screen and (min-height: 200px) {
    #header h2 {
        margin-top: 10vh;
    }
}

@media screen and (min-height: 300px) {
    #header h2 {
        margin-top: 15vh;
    }
}

@media screen and (min-height: 400px) {
    #header h2 {
        margin-top: 30vh;
    }
}

@media screen and (min-height: 501px) {
    #header h2 {
        margin-top: 35vh;
    }
}

@media screen and (min-height: 700px) {
    #header h2 {
        margin-top: 50vh;
    }
}

@media screen and (min-width: 800px) {
    #pobude div, #aktivnosti div, #dejstva div {
        max-width: 70vw;
    }
}

#header h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.7em;
}

#header h2 em, #header h2 strong {
    background-color: opacify($color: $offwhite, $amount: 0.2);
    color: $dark;
    padding: 0 0.3em;
    font-style: normal;
    display: inline-block;
}

#header h2 strong {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 300;
}

h2 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 1em;
    text-transform: uppercase;
}

h3 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
    font-weight: 300;
}

h4 {
    margin-bottom: 0.5em;
    font-weight: 500;
}

nav {
    font-family: "Jost", sans-serif;
}

nav ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

nav li {
    text-transform: uppercase;
    font-size: 0.95em;
    letter-spacing: 2px;
}

nav a {
    text-decoration: none;
    color: $white;
}

@media screen and (min-width: 320px) {
    h1 {
        margin: 0 auto;
    }

    nav {
        padding: 0;
    }

    nav ul {
        justify-content: space-evenly;
    }

    nav li {
        padding: 0.2em;
    }

    nav .podpora {
        display: none;
    }

    #header p .podpora {
        display: inline-block;
        color: $offwhite;
        text-transform: uppercase;
        text-decoration: none;
        font-family: "Jost", sans-serif;
    }

    #header p {
        text-align: center;
    }
}

@media screen and (min-width: 650px) {
    nav .podpora {
        display: inherit;
    }

    #header p .podpora {
        display: none;
    }
}

@media screen and (min-width: 800px) {
    h1 {
        margin: 0;
    }

    nav {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 0 2em;
    }

    nav ul {
        margin: 0;
    }

    nav li {
        padding: 1em;
    }
}

a.podpora {
    background-color: $colorful;
    padding: 1em;
    border-radius: 10px;
    letter-spacing: 5px;
}

a.podpora:hover {
    background-color: darken($colorful, 10%);
}

#pobude {
    background-color: $dark;
    margin-bottom: 0;
    padding: 4em 0;
}

#pobude p {
    font-size: medium;
    line-height: 1.7em;
}

#pobude div {
    background-color: $dark;
    margin: 0 auto;
    color: $offwhite;
    border-radius: 10px;
}

@media screen and (min-width: 320px) {
    #pobude div {
        border-radius: 0;
        padding: 1em;
    }
}

@media screen and (min-width: 800px) {
    #pobude div {
        padding: 2em 2em 0 2em;
        margin-top: -200px;
        border-radius: 10px;
    }
}

#pobude .center {
    text-align: center;
    padding: 2em 0 0;
}

#pobude .podpora {
    margin: 2em 0;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
}

#aktivnosti {
    background-color: $offwhite;
}

#aktivnosti #archive {
    display: none;
}

#aktivnosti.open #archive {
    display: block;
}

#aktivnosti.open a.more-activities {
    display: none;
}

#aktivnosti div {
    padding: 4em 0;
    margin-bottom: 4em;
    margin: 0 auto;
}

#aktivnosti ol li {
    list-style-type: decimal;
    margin-left: 2.5em;
    margin-bottom: 1em;
}

#aktivnosti ol {
    margin-bottom: 2em;
}

#dejstva {
    background: $dark;
}

#dejstva h2 {
    color: $offwhite;
}

#dejstva div {
    margin: 0 auto;
    padding-top: 4em;
}

#dejstva summary p {
    font-size: 1.1em;
}

#dejstva .open summary {
    border-bottom: dotted 1px lighten($font, 50%);
    margin-bottom: 2em;
}

#dejstva ul {
    //list-style: disc;
    margin-bottom: 1.5em;
    //margin-left: 1.2em;
}

#dejstva li {
    line-height: 1.7em;
}

#dejstva aside {
    background-color: $offwhite;
    border-radius: 10px;
}

@media screen and (min-width: 320px) {
    #dejstva aside {
        padding: 1em;
        margin: 0 0 1em;
    }

    #dejstva blockquote {
        margin-left: 1em;
        margin-right: 1em;
    }
}

@media screen and (min-width: 800px) {
    #dejstva aside {
        padding: 2em;
        margin: 0 0 2em;
    }

    #dejstva blockquote {
        margin-left: 2em;
        margin-right: 2em;
    }
}

#dejstva article {
    display: none;
}

#dejstva .open article {
    display: block;
}

#dejstva p.bang {
    text-align: center;
    font-size: 1.5em;
    font-family: "Jost", sans-serif;
    color: $offwhite;
    padding-bottom: 1em;
}

#dejstva .open .more {
    display: none;
}

#dejstva blockquote {
    color: lighten($font, 20%);
}

#pridruzi-se {
    background-color: $offwhite;
}

#pridruzi-se div {
    max-width: 40vw;
    margin: 0 auto;
    padding: 4em 0;
}

#pridruzi-se h2 {
    font-size: large;
}

#pridruzi-se form {
    width: 80%;
    margin-bottom: 2em;
}

#pridruzi-se form label {
    display: block;
    margin: 10px 0 5px;
    font-size: 0.8em;
}

#pridruzi-se form input[type="text"], form input[type="email"] {
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid lighten($font, 50%);
}

#pridruzi-se form input[type="submit"] {
    border-radius: 10px;
    background-color: $colorful;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
}

#pridruzi-se p.footnote {
    font-size: 0.8em;
    opacity: 0.8;
}

#o-nas {
    background-color: $dark;
    padding: 4em 0;
}

#o-nas div {
    margin: 0 auto;
    color: $offwhite;
}

@media screen and (min-width: 320px) {
    #pobude div, #aktivnosti div, #dejstva div, #o-nas div, #pridruzi-se div, #varstvo {
        max-width: 90vw;
    }
}

@media screen and (min-width: 800px) {
    #pobude div, #aktivnosti div, #dejstva div, #o-nas div, #varstvo {
        max-width: 70vw;
    }

    #pridruzi-se div {
        max-width: 50vw;
    }
}

#varstvo {
    padding: 4em 0;
    margin: 0 auto;
    color: $offwhite;
}

footer ul {
    display: flex;
    justify-content: center;
    gap: 1em;
    opacity: 0.8;
}

footer {
    background-color: $dark;
    padding: 0 0 2em 0;
    color: $white;
    font-size: 0.9em;
    font-family: "Jost", sans-serif;
}